import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyDjos43JCsCVmZJ-G7F5hlzV81xbGiyB3w',
  authDomain: 'codex-excelso-espiritu-santo.firebaseapp.com',
  projectId: 'codex-excelso-espiritu-santo',
  storageBucket: 'codex-excelso-espiritu-santo.appspot.com',
  messagingSenderId: '659984225102',
  appId: '1:659984225102:web:a4872bf0bbec8800a5441f',
  measurementId: 'G-B1F8SDY4T1',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
